<template>
  <section class="views">
    <!-- header -->
    <Headers select="2"></Headers>

    <!-- banner  -->
    <div class="posters">
      <van-image
        width="100%"
        :src="require('@/assets/image/hodgepodge/pcbanner_team.jpg')"
      />
    </div>

    <div class="content">
      <div class="viewsList">
        <ul class="animate__animated animate__bounceInLeft">
          <li
            v-for="(item, index) in pdfList"
            :key="index"
            @click="pdfBut(item)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
   
  <script>
import Headers from '@/components/header/pc_header_team'
export default {
  name: 'material_team',
  data() {
    return {
      pdfList: [
        {
          name: '比賽誦材下載',
          url: 'https://materialv3-1256597606.cos.accelerate.myqcloud.com/chineserd/dev/2024-06/31e7328803893f41f2bd07901bf80a7a.pdf'
        }
      ]
    }
  },
  computed: {},
  components: {
    Headers
  },
  created() {},
  mounted() {},
  methods: {
    getPdf(e) {
      this.$axios({
        url: e.url,
        method: 'get',
        responseType: 'blob'
      })
        .then((response) => {
          this.$toast.clear()
          this.$toast.success('開始下載')
          // 将文件流转化为 Blob 对象，并创建 a 标签进行下载
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const downloadElement = document.createElement('a')
          const href = window.URL.createObjectURL(blob) // 创建下载链接
          downloadElement.href = href
          downloadElement.download = e.name // 文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 下载
          document.body.removeChild(downloadElement) // 下载完成后移除元素
          window.URL.revokeObjectURL(href) // 释放占用的内存空间
        })
        .catch((error) => {
          this.$toast.clear()
          console.error(error) // 处理异常情况
        })
    },
    pdfBut(e) {
      // this.$dialog
      //   .alert({
      //     title: '提示',
      //     message: '誦材暫未開放下載',
      //     confirmButtonText: '確認'
      //   })
      //   .then(() => {
      //     // on close
      //   })

      if (e.url) {
        // this.getPdf(e);
        window.open(e.url, '_blank')
      } else {
        this.$dialog
          .alert({
            title: '提示',
            message: '決賽誦材暫未開放下載',
            confirmButtonText: '確認'
          })
          .then(() => {
            // on close
          })
      }
    }
  }
}
</script>
  
  <style lang="scss" scoped>
.views {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background: #ff782f;

  .posters {
    width: 100%;
    display: flex;
    position: relative;

    img {
      width: 100%;
    }

    .posters_ewm {
      position: absolute;
      width: 1024px;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: right;

      .ewm {
        width: 161px;
        height: 189px;
        background: rgba(255, 255, 255, 0.63);
        border-radius: 16px 16px 16px 16px;
        padding: 8px;
        box-sizing: border-box;

        .ewm2 {
          width: 100%;
          height: 100%;
          padding: 6px;
          background: #fdcf1d;
          border-radius: 12px 12px 12px 12px;
          box-sizing: border-box;

          .ewm3 {
            width: 132px;
            height: 132px;
            background: #ffffff;
            border-radius: 8px 8px 8px 8px;
            margin-bottom: 6px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

          .ewm3_value {
            font-size: 16px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #323232;
            line-height: 19px;
            text-align: center;
          }
        }
      }
    }
  }

  .content {
    width: 100%;
    margin: auto;

    .viewsList {
      width: 100%;
      max-width: 1024px;
      margin: 64px auto 50px auto;
      padding: 0.32rem;
      box-sizing: border-box;

      ul {
        width: 100%;

        li {
          width: 640px;
          height: 124px;
          margin: auto;
          background: #fff8de;
          box-shadow: 0px 0px 4px 0px rgba(126, 56, 0, 0.26);
          border: 4px solid #fff0e1;
          border-radius: 16px;
          margin-bottom: 32px;
          font-size: 24px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ff782f;
          line-height: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }
  }
}
</style>